import * as Type from '../../types/proyectosTypes';


const defaultState = {
    proyectos: [],
    imagenes: [],
    proyectproductos: {
        count: 0,
        rows: [],
        manzanos: [],
        lotes: []
    },
    loadMore: false,
    paginas: 1,
    totalItem: 0,
    rows: [],
    manzanos: [],
    lotes: [],
    datos_lote: []
};

const concatCustomList = (array1, array2, pagina = 1) => {
    if (array1.length > 0 && pagina > 1) {
        return [...array1, ...array2];
    } else {
        return array2;
    }
};
const concatCustomLista2 = (array1, array2, pagina) => {
    if (array1.length > 0 && pagina > 1) {
        return [...array1, ...array2];
    } else {
        return array2;
    }
};


export default function reducer (state = defaultState, action) {    
    
    switch (action.type) {
        case Type.PROJECT_LIST:
            return {
                ...state,
                proyectos: concatCustomList(state.proyectos, action.proyectos)
            };
        case Type.IMAGENS_PROYECT:
            return {
                ...state,
                imagenes: action.imagenes
            };
        case Type.PRODUCT_PROYECTS:
            return {
                ...state,
                rows: action.rows,// concatCustomLista2(state.rows, action.rows,state.paginas),                
                paginas: action.pages,
                loadMore: action.count > 10,
                totalItem: action.count,
                manzanos: action.manzanos,
                lotes: action.lotes
            };
            case Type.PROPERTY_DETAILS:
                return{
                    ...state,
                    datos_lote: concatCustomList(state.datos_lote, action.datos_lote)
                };
        case Type.PRODUCT_PROYECTS_NEXT_PAGE:
            return {
                ...state,
                paginas: action.pages,
                rows: concatCustomLista2(state.rows, action.rows, action.pages),
                loadMore: action.count > 10,
                totalItem: action.count,
            };
        case Type.PRODUCTOS_NO_MORE_PAGE:
            return {
                ...state,
                loadMore: false,
            }
        case Type.CLEAR_PRODUCTS:
            return {
                ...state,
                loadMore: false,
                paginas: 1,
                totalItem: 0,
                rows: [],
                manzanos: [],
                lotes: [],
                datos_lote: []
            }
        default: {
            return state;
        }
    }
};
