import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ActionsLogin from "../redux/actions/login/loginAction";
import * as ActionsMessage from "../redux/actions/message/messageActions";
import * as NotificacionAction from "../redux/actions/notificacion/notificacionActions";
import * as PromocionAction from "../redux/actions/home/homeActions";

import { socket, connect, disconnect } from "../lib/socket";

const useSocket = ({ routerRef }) => {
  const { ci, userToken } = useSelector((state) => state.load);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userToken) {
      connect(ci);

      socket.on("unlogin", function (data) {
        if (data.token === userToken) {
          socket.off("unlogin");
          socket.off("notification");
          socket.off("promocion");
          disconnect();
          dispatch(ActionsLogin.closeSesionPusher(routerRef.current.history));
        }
      });

      socket.on("notification", function (data) {
        if (data && data.hasOwnProperty("message")) {
          dispatch(ActionsMessage.showNotification({ message: data.message }));
          dispatch(
            NotificacionAction.cantidadNotificacionesNoLeidas(userToken)
          );
        }
      });

      socket.on("promocion", function (data) {
        if (data && data.hasOwnProperty("message")) {
          dispatch(ActionsMessage.showNotification({ message: data.message }));
          dispatch(PromocionAction.listarPromociones(ci, 2, userToken));
        }
      });
    } else {
      if (socket && socket.connected) {
        socket.off("unlogin");
        socket.off("notification");
        socket.off("promocion");
        disconnect();
      }
    }

    return () => {
      if (socket && socket.connected) {
        socket.off("unlogin");
        socket.off("notification");
        socket.off("promocion");
        disconnect();
      }
    };
  }, [userToken]);

  return { socket };
};

export default useSocket;
