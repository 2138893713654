import { Typography } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import moment from "moment";
import Swiper from "react-id-swiper";
import { CANTIDAD_NO_LEIDOS } from "../../redux/types/notificacionTypes";
import * as Actions from "../../redux/actions/message/messageActions";
// import ReactIdSwiperCustom from "react-id-swiper/lib/ReactIdSwiper.custom";
// import { Swiper, Navigation, Pagination } from "swiper/js/swiper.esm";
import "swiper/css/swiper.min.css";
import "../../styles/sliderNotificaciones.css";
import {
  requestGetWithoutDispatch,
  requestPostWhithHeaders,
} from "../../service/request";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const NotificationItem = ({ notificacion }) => (
  <div className="swiper-slide" style={{ paddingInline: 35 }}>
    <Typography component="h6" variant="h6">
      {notificacion.titulo}
    </Typography>
    <Typography variant="subtitle2" color="textSecondary" className="mb-3">
      {moment(notificacion.fecha_Hora).utc().format("lll")}
    </Typography>
    <Typography variant="body2">
      {notificacion.descripcion[0].detalle}
    </Typography>
  </div>
);

const SliderNotificaciones = ({ notificaciones }) => {
  const dispatch = useDispatch();
  const { i18next } = useTranslation();
  const desactivarNotificacion = (index) => {
    const notificacionADesactivar = notificaciones[index];
    const headers = {
      idnotificacion: notificacionADesactivar.idnotificacion,
    };
    requestPostWhithHeaders(
      "/notificacion/update/leido",
      null,
      headers,
      () => {}
    )
      .then((response) => {
        if (response && response.status === 0) {
          requestGetWithoutDispatch(
            "/notificacion/cantidad/notificaciones/noleidos",
            headers,
            () => {}
          )
            .then((response) => {
              if (response && response.data) {
                dispatch({
                  type: CANTIDAD_NO_LEIDOS,
                  notificacionesNoLeidas: response.data.cantidad,
                });
              }
            })
            .catch((error) => {
              dispatch(
                Actions.showMessage({
                  message: i18next.t("reducer.errorCargarDatos"),
                  variant: "error",
                })
              );
            });
        }
      })
      .catch((error) => {
        console.log("log error " + error);
      });
  };

  useEffect(() => {
    if (notificaciones.length > 0) {
      desactivarNotificacion(0);
    }
  }, []);

  return (
    <Swiper
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      getSwiper={(swiper) => {
        swiper.on("slideChange", () => {
          desactivarNotificacion(swiper.activeIndex);
        });
      }}
      spaceBetween={30}
    >
      {notificaciones.length > 1 ? (
        notificaciones.map((notificacion) => (
          <NotificationItem
            notificacion={notificacion}
            key={notificacion.idnotificacion.toString()}
          />
        ))
      ) : (
        <NotificationItem
          notificacion={notificaciones[0]}
          withPadding={false}
        />
      )}
    </Swiper>
  );
};

export default SliderNotificaciones;
