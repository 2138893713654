import * as Types from "../../types/notificacionTypes";
import * as Actions from "./../message/messageActions";
import {
  requestGet,
  requestGetWhithHeaders,
  requestGetWithoutDispatch,
} from "../../../service/request";
import i18next from "i18next";

export function cantidadNotificacionesNoLeidas(token) {
  return (dispatch) => {
    if (!navigator.onLine) {
      dispatch(
        Actions.showMessage({
          message: i18next.t("reducer.sinAccesoInternet"),
          variant: "warning",
        })
      );
      return;
    }
    const headers = {
      token: token,
    };
    requestGetWithoutDispatch(
      "/notificacion/cantidad/notificaciones/noleidos",
      headers,
      dispatch
    )
      .then((response) => {
        if (response && response.data) {
          dispatch({
            type: Types.CANTIDAD_NO_LEIDOS,
            notificacionesNoLeidas: response.data.cantidad,
          });
        }
      })
      .catch((error) => {
        dispatch(
          Actions.showMessage({
            message: i18next.t("reducer.errorCargarDatos"),
            variant: "error",
          })
        );
      });
  };
}
