import * as Type from "../../types/notificacionTypes";

const defaultState = {
  notificacionesNoLeidas: 0,
  notificaciones: [],
};

export default function reducer(initialState = defaultState, action) {
  let state = { ...defaultState, ...initialState };
  switch (action.type) {
    case Type.CANTIDAD_NO_LEIDOS:
      return {
        ...state,
        notificacionesNoLeidas: action.notificacionesNoLeidas,
      };
    case Type.CARGAR_NOTIFICACIONES:
      return {
        ...state,
        notificaciones: action.notificaciones,
      };
    case Type.AGREGAR_NOTIFICACIONES:
      return {
        ...state,
        notificacionesNoLeidas: [
          ...state.notificaciones,
          ...action.notificaciones,
        ],
      };
    default: {
      return state;
    }
  }
}
