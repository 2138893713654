import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import React from "react";
import SliderNotificaciones from "./SliderNotificaciones";
import { useTranslation } from "react-i18next";

const DialogNotificacionMultiple = ({
  notificaciones,
  modalVisible,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={modalVisible}>
      <DialogContent style={{ paddingInline: 0 }}>
        <SliderNotificaciones notificaciones={notificaciones} />
      </DialogContent>
      <DialogActions>
        <Button size="small" color="primary" onClick={onClose}>
          {t("loginPage.cerrar")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogNotificacionMultiple;
