import { useFormik } from "formik";
import * as yup from "yup";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EMPRESAS_QUE_FACTURAN } from "../config/config.json";
import { requestGet, requestPost } from "../service/request";

const cedulaIDentidad = 1;

const useDatosFactura = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { userToken, ci } = useSelector((state) => state.load);

  const [tiposDocumento, setTiposDocumento] = useState(null);
  const [datosFactura, setDatosFactura] = useState(null);
  const [openDatosFactura, setOpenDatosFactura] = useState(false);
  const [facturaCliente, setFacturaCliente] = useState(false);
  const [registrado, setRegistrado] = useState(false);

  const [tipoDoc, setTipoDoc] = useState(0);

  const getEmpresaClienteFactura = async () => {
    const data = { docId: ci, empresas: EMPRESAS_QUE_FACTURAN };

    try {
      const response = await requestPost(
        "/factura/cliente/empresas",
        data,
        dispatch
      );

      if (response && response.status === 0) {
        setFacturaCliente(response.data);
      }
    } catch (error) {}
  };

  const getTipoDocumentos = async () => {
    try {
      const response = await requestGet(
        "/factura/documento",
        { token: userToken },
        dispatch
      );

      if (response && response.status === 0) {
        setTiposDocumento(response.data);
      }
    } catch (error) {}
  };

  const getDatosFactura = async () => {
    try {
      const response = await requestGet(
        "/factura/cliente",
        { doc_id: ci },
        dispatch
      );

      if (response && response.status === 0) {
        setDatosFactura(response.data);
      }
    } catch (error) {}
  };

  const setGuardarDatosFactura = async (data) => {
    let datoTelefono = `${data.prefijo}-${data.telefono}`;
    let datosFactura = { ...data, telefono: datoTelefono };
    delete datosFactura.prefijo;
    delete datosFactura.idDatosFactura;

    if (parseInt(data.tipoDocumento) !== cedulaIDentidad) {
      datosFactura.complemento = "";
    }

    datosFactura.nroDocumento = datosFactura.nroDocumento.toUpperCase();
    datosFactura.razonSocial = datosFactura.razonSocial.toUpperCase();
    datosFactura.complemento = datosFactura.complemento.toUpperCase();

    try {
      const response = await requestPost(
        "/factura/cliente/guardar",
        datosFactura,
        dispatch
      );

      if (response && response.status === 0) {
        setDatosFactura([response.data]);
        setRegistrado(true);
      }
    } catch (error) {}
  };

  const esNumero = (valor) => {
    return /^[0-9]*$/.test(valor);
  };

  const esAlfaNumerico = (valor) => {
    return /^[a-zA-Z0-9]*$/.test(valor);
  };

  const validationSchema = yup.object({
    nroDocumento: yup
      .string()
      .required(t("datosFactura.errorDocumento"))
      .test("esNum", t("datosFactura.errorDocumentoNoNumero"), (val) => {
        const tipoDocumento = formik.values.tipoDocumento;
        if (parseInt(tipoDocumento) === 1 || parseInt(tipoDocumento) === 20) {
          return esNumero(val);
        }
        return true;
      })
      .test(
        "minlen",
        t("datosFactura.documentoMinDigitos", { minDigitos: 5 }),
        (val) => val && val.toString().length >= 5
      )
      .test(
        "maxlen",
        t("datosFactura.documentoMaxDigitos", { maxDigitos: 20 }),
        (val) => {
          return val && val.toString().length <= 20;
        }
      ),
    complemento: yup
      .string()
      .test(
        "maxlen",
        t("datosFactura.errorComplemento", { maxDigitos: 2 }),
        (val) => (val ? val.toString().length <= 2 : true)
      )
      .test("esLetra", t("datosFactura.errorCompAlfaNum"), (val) => {
        return esAlfaNumerico(val);
      }),
    tipoDocumento: yup
      .number()
      .typeError(t("datosFactura.errorTipoDocumento"))
      .min(-1, t("datosFactura.errorTipoDocumento"))
      .required(t("datosFactura.errorTipoDocumento")),
    razonSocial: yup.string().required(t("datosFactura.razonSocialRequerido")),
    correo: yup
      .string()
      .email(t("datosFactura.errorCorreo"))
      .required(t("datosFactura.correoRequerido")),
    telefono: yup
      .string()
      .matches(/^[0-9]*$/, t("datosFactura.telefonoNoNumero"))
      .test(
        "minlen",
        t("datosFactura.telefonoMinDigitos", { minDigitos: 5 }),
        (val) => val && val.toString().length >= 5
      )
      .test(
        "maxlen",
        t("datosFactura.telefonoMaxDigitos", { maxDigitos: 10 }),
        (val) => val && val.toString().length <= 10
      ),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      docId: "",
      tipoDocumento: "",
      nroDocumento: "",
      complemento: "",
      correo: "",
      razonSocial: "",
      prefijo: "",
      telefono: "",
    },
    onSubmit: async (data) => {
      await setGuardarDatosFactura(data);
    },
  });

  useEffect(() => {
    getEmpresaClienteFactura();
  }, []);

  useEffect(() => {
    if (facturaCliente) {
      getTipoDocumentos();
      getDatosFactura();
    }
  }, [facturaCliente]);

  useEffect(() => {
    if (datosFactura && datosFactura.length > 0) {
      let telefono = datosFactura[0].telefono;
      let prefijo = "+591";
      if (telefono.includes("-")) {
        let telefonoSplit = telefono.split("-");
        prefijo = telefonoSplit[0];
        telefono = telefonoSplit[1];
      }
      formik.setValues({ ...datosFactura[0], prefijo, telefono });
    }
  }, [datosFactura]);

  useEffect(() => {
    formik.setValues({ ...formik.values });
  }, [tipoDoc]);

  return {
    facturaCliente,
    formik,
    tiposDocumento,
    datosFactura,
    openDatosFactura,
    setOpenDatosFactura,
    getDatosFactura,
    registrado,
    setRegistrado,
    setTipoDoc,
  };
};

export default useDatosFactura;
