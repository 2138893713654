import React, { useEffect, useState } from "react";
import RoutersApp from "./routes/RoutesApp";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";
import moment from "moment";
import "moment/min/locales";
import { useTranslation, initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import * as Action from "./redux/actions/login/loginAction";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { checkMaitenance } from "./redux/actions/maintenance";
import Maintenance from "./pages/other/Maintenance";
import en from "./translations/en";
import es from "./translations/es";
import pt from "./translations/pt";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}));

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en", // use en if detected lng is not available
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      pt: {
        translation: pt,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ["es", "en", "pt"],
  });

i18n.on("languageChanged", function (lng) {
  localStorage.setItem("currentLanguage", lng);
  moment.locale(lng);
});

const App = () => {
  const { userToken, load } = useSelector((stateSelector) => {
    return stateSelector.load;
  });

  const { i18n: i18next } = useTranslation();

  const { active, loading } = useSelector((state) => state.maintenance);
  const dispatch = useDispatch();
  const classes = useStyles();

  const syncLanguage = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchLanguage = searchParams.get("lang");
    const currentLanguage = localStorage.getItem("currentLanguage");
    if (!currentLanguage) {
      const navigatorLanguage = navigator.language || navigator.userLanguage;
      if (navigatorLanguage) {
        const saveLanguage =
          navigatorLanguage.indexOf("-") > -1
            ? navigatorLanguage.split("-")[0]
            : navigatorLanguage;
        localStorage.setItem(
          "currentLanguage",
          searchLanguage ? searchLanguage : saveLanguage
        );
        i18next.changeLanguage(searchLanguage ? searchLanguage : saveLanguage);
      } else {
        localStorage.setItem(
          "currentLanguage",
          searchLanguage ? searchLanguage : "en"
        );
        i18next.changeLanguage(searchLanguage ? searchLanguage : "en");
      }
    } else {
      if (searchLanguage) {
        localStorage.setItem("currentLanguage", searchLanguage);
        i18next.changeLanguage(searchLanguage);
      }
    }
  };

  useEffect(() => {
    dispatch(checkMaitenance());
    syncLanguage();
    const tokenStorage = localStorage.getItem("token");
    if (tokenStorage && !userToken) {
      dispatch(Action.recargarSesion(tokenStorage));
    } else {
      dispatch(Action.loadFinish());
    }
  }, []);

  if (active) {
    return <Maintenance />;
  }

  if (!load || loading) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }
  return <RoutersApp />;
};

App.propTypes = {
  // dispatch: PropTypes.func
};

export default App;
