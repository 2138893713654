import crypto from 'crypto';
const privateKey = 'FBDF08E0405E55C4FCA0729AF055AC75';
  // Encrypt
export const  encrypt=(params)=> {
  const iv = new Buffer.alloc(16);
  let cipher = crypto.createCipheriv('aes-256-cbc', privateKey, iv);
  let crypt = cipher.update(params, 'utf8', 'base64') + cipher.final('base64');
  return crypt;
}
// Decrypt
export const decrypt = (params) => {
  const iv = new Buffer.alloc(16);
  let decipher = crypto.createDecipheriv('aes-256-cbc', privateKey, iv);
  let decrypted = decipher.update(params, 'base64', 'utf-8') + decipher.final('utf-8');
  return decrypted;
};
export const  encrypt2=(params)=> {
  const iv = new Buffer.alloc(16);
  let cipher = crypto.createCipheriv('aes-256-cbc', privateKey, iv);
  let crypt = cipher.update(params, 'utf8', 'Hex') + cipher.final('Hex');
  return crypt;
}
// Decrypt
export const decrypt2 = (params) => {
  const iv = new Buffer.alloc(16);
  let decipher = crypto.createDecipheriv('aes-256-cbc', privateKey, iv);
  let decrypted = decipher.update(params, 'Hex', 'utf-8') + decipher.final('utf-8');
  return decrypted;
};

