import React, { useEffect, useState } from "react";
import maintenanceImg from "../../assets/images/maintenance.png";
import sionLogo from "../../components/logoAnimated/logosion.png";
import { Typography, Container, Button } from "@material-ui/core";
import { useTimer } from "react-timer-hook";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Maintenance = () => {
  const { endDate } = useSelector((state) => state.maintenance);
  const { t } = useTranslation();

  return (
    <Container>
      <img
        src={maintenanceImg}
        style={{ width: "100%", height: "350px", objectFit: "contain" }}
      />
      <img
        src={sionLogo}
        style={{
          width: "100%",
          height: "170px",
          marginTop: "-80px",
          marginBottom: "-50px",
          objectFit: "contain",
        }}
      />
      <Typography className="text-center mt-3" variant="h4">
        {t("mantenimientoPage.enMantenimiento")}
      </Typography>
      <Typography className="text-center" variant="h4">
        {t("mantenimientoPage.volveremosEn")}
      </Typography>
      {endDate && (
        <TimerDescription expiryTimestamp={moment(endDate).toDate()} />
      )}
    </Container>
  );
};

const TimerDescription = ({ expiryTimestamp }) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    autoStart: true,
    onExpire: () => console.warn("onExpire called"),
  });

  const { t } = useTranslation();
  return (
    <div className="align-self-center">
      <div className="d-flex flex-row justify-content-center align-items-center">
        <div className="text-center mr-3">
          <Typography className="text-center font-weight-bold" variant="h3">
            {days < 10 ? "0" + days : days}
          </Typography>
          <Typography variant="subtitle2">
            {t("mantenimientoPage.dias")}
          </Typography>
        </div>

        <div className="text-center mr-3">
          <Typography className="text-center font-weight-bold" variant="h3">
            {hours < 10 ? "0" + hours : hours}
          </Typography>
          <Typography variant="subtitle2">
            {t("mantenimientoPage.horas")}
          </Typography>
        </div>

        <div className="text-center mr-3">
          <Typography className="text-center font-weight-bold" variant="h3">
            {minutes < 10 ? "0" + minutes : minutes}
          </Typography>
          <Typography variant="subtitle2">
            {t("mantenimientoPage.minutos")}
          </Typography>
        </div>

        <div className="text-center">
          <Typography className="text-center font-weight-bold" variant="h3">
            {seconds < 10 ? "0" + seconds : seconds}
          </Typography>
          <Typography variant="subtitle2">
            {t("mantenimientoPage.segundos")}
          </Typography>
        </div>
      </div>
      {!isRunning && (
        <p className="text-center mt-4">
          <Button
            variant="contained"
            color="primary"
            onClick={() => (window.location = "/")}
          >
            {t("mantenimientoPage.volverAIntentar")}
          </Button>
        </p>
      )}
    </div>
  );
};

export default Maintenance;
