
  import * as Types from './../../types/prereservaType';
  const defaultState = {
      loadPrereserva:false,
      modalPrereserva:false,
      mensajePrereserva:'',
      tipoPrereserva:'',
      tituloModal:'',
      tipoModal:'',
      respuesta: null
  };
  
  export default function reducer(initialState = defaultState, action) {
      let state = {...defaultState, ...initialState}
      switch (action.type) {
          case Types.PRERESERVA_PRODUCTO:
              return {
                  ...state,
                  modalPrereserva: true,
                  mensajePrereserva:action.mensaje,
                  tipoPrereserva:action.tipoPrereserva,
                  tituloModal:action.tituloMensaje, 
                  tipoModal:'success',
                  respuesta: action.respuesta          
              };     
            case Types.PRERESERVA_CLOSE_MODALMESSAGE:
                return {
                    ...state,
                    modalPrereserva: false,
                    mensajePrereserva:'',
                    tipoPrereserva:'',
                    tituloModal:'',  
                    tipoModal:''    
                };  
            case Types.PRERESERVA_PRODUCTO_WARNING:
                return {
                    ...state,
                    modalPrereserva: true,
                    mensajePrereserva:action.mensaje,
                    tipoPrereserva:action.tipoPrereserva,
                    tituloModal:action.tituloMensaje, 
                    tipoModal:'warning'    
                }; 
          default: {
              return state;
          }
      }
  }