import {
  LOADING_SALDO_CUENTA
} from "../../actions/cuentaSaldo/cuentaSaldoActions";

const defaultState = {
    load:false
};

export default function reducer(initialState = defaultState, action) {
    let state = {...defaultState, ...initialState}
    switch (action.type) {
        case LOADING_SALDO_CUENTA:
            return {
                ...state,
                load: action.load                
            };     
        default: {
            return state;
        }
    }
}