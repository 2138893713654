
import { authType } from "../../../lib/FunsionesGenerales";
import {
  LOADING_LOGIN,
  SIGN_OUT,
  SIGN_FINISH,
  UPDATE_AUTH_TYPE,
  UPDATE_OPEN_DIALOG,
  HIDE_CI_MODAL,
  CAMBIAR_EXPANDIR_RELOJ
} from "../../actions/login/loginAction";
import config from '../../../config/config.json'

const defaultState = {
    load:false,
    userToken: null,
    tipo:'', //mobil o web
    idDispositivo:null,
    puedeIngresar:0, //sinInfo
    tipoUsuario:0, // 2login
    nombreUsuario:'',
    esVendedor:0,
    mensajeVendedor:null,
    ci:'',
    iat:0,
    exp:0,
    useFingerprint: false,
    typeAuth: authType.TIPO_USUARIO_PASSWORD,
    activateAuthType: false,
    openDialog:false,
    cantidadDiasLoginCarnet: 0,
    mostrarModalCarnet: false,
    esCajero: false,
    expandirReloj: false
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case LOADING_LOGIN:
            return {
                ...state,
                load: true,
                userToken:action.token,
                tipo:action.tipo,
                idDispositivo:action.idDispositivo,
                puedeIngresar:action.puedeIngresar,
                tipoUsuario:action.TipoUsuario,
                nombreUsuario:action.nombreUsuario,
                esVendedor:action.esVendedor,
                mensajeVendedor:action.mensajeVendedor,
                ci:action.ci,
                iat:action.iat,
                exp:action.exp,
                cantidadDiasLoginCarnet: action.cantidadDiasLoginCarnet,
                mostrarModalCarnet: action.esVendedor === 1 && action.cantidadDiasLoginCarnet > 0,
                esCajero: action.esCajero
            };
        case SIGN_OUT:
            return {
                ...state,
                 load: true,
                userToken:null,
                tipo:'',
                idDispositivo:null,
                puedeIngresar:0, //sinInfo
                tipoUsuario:0, // 2login
                nombreUsuario:'',
                esVendedor:0,
                mensajeVendedor:null,
                cantidadDiasLoginCarnet: 0,
                mostrarModalCarnet: false,
                ci:'',
                iat:0,
                exp:0,
            
            };  
        case SIGN_FINISH:
            return {
                ...state,
                 load: true,                
            };      
        case UPDATE_AUTH_TYPE: 
            return {
                ...state,
                useFingerprint: action.useFingerprint,
                typeAuth: action.typeAuth,
                activateAuthType: action.activateAuthType
            }      
        case UPDATE_OPEN_DIALOG: 
            localStorage.setItem('showModal', '0')
            return {
                ...state,
                openDialog: action.open
            };  
        case HIDE_CI_MODAL:
            return {
                ...state,
                mostrarModalCarnet: false,                
            };     
        case CAMBIAR_EXPANDIR_RELOJ:
            return {
                ...state,
                expandirReloj: !state.expandirReloj
            }       
        default: {
            return state;
        }
    }
}