import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestGet } from "../service/request";
import * as MessageActions from "../redux/actions/message/messageActions";
const useNotificacionesPendientes = () => {
  const dispatch = useDispatch();
  const { userToken, ci } = useSelector((state) => state.load);
  const { notificacionesNoLeidas } = useSelector(
    (state) => state.notificaciones
  );
  const [notificaciones, setNotificaciones] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [accionRealizada, setAccionRealizada] = useState(false);

  const cerrarModal = () => setModalVisible(false);

  const cargarNotificaciones = () => {
    setAccionRealizada(true);
    const headers = {
      ci: ci,
      tipo: 1,
      token: userToken,
      pagina: 1,
    };
    requestGet("/notificacion/obtener/notificaciones", headers, dispatch)
      .then((response) => {
        if (response && response.status === 0) {
          if (response.data.rows.length > 0) {
            const noLeidas = response.data.rows.filter(
              (notificacion) =>
                notificacion.descripcion.length > 0 &&
                notificacion.descripcion[0].estadoNotificacion === "NO LEIDO"
            );
            setNotificaciones(noLeidas);
            setModalVisible(noLeidas.length > 0);
          }
        }
      })
      .catch((error) => {
        dispatch(
          MessageActions.showMessage({
            message: "Error al cargar los datos inténtelo mas tarde, por favor",
            variant: "error",
          })
        );
      });
  };

  useEffect(() => {
    if (notificacionesNoLeidas > 0 && !accionRealizada) {
      cargarNotificaciones();
    }
  }, [notificacionesNoLeidas]);

  return {
    notificaciones,
    modalVisible,
    cerrarModal,
  };
};

export default useNotificacionesPendientes;
