import {
  ACTIVATE_MAINTENANCE,
  DEACTIVATE_MAINTENANCE,
} from "../actions/maintenance";

const initialState = {
  active: false,
  endDate: null,
  loading: true,
};

const maintenance = (state = initialState, action) => {
  if (action.type === ACTIVATE_MAINTENANCE) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ code: "MAINTENANCE", data: { ...action } })
      );
    }
    return {
      active: true,
      endDate: action.endDate,
      loading: false,
    };
  } else if (action.type === DEACTIVATE_MAINTENANCE) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ code: "MAINTENANCE", data: { ...action } })
      );
    }
    return {
      active: false,
      endDate: null,
      loading: false,
    };
  } else {
    return state;
  }
};

export default maintenance;
