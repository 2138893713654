import {applyMiddleware, createStore, compose} from 'redux';
import rootReducer from './reducers/rootReducer';
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from './middleware/logger';
import { composeWithDevTools } from "redux-devtools-extension";
// const middlewareEnhancer = applyMiddleware(loggerMiddleware, thunkMiddleware)
import thunk from 'redux-thunk'

const middleware = [thunk]

const initialState = {}

const configureStore =()=>{
    return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk))
        // initialState ,
    // compose(applyMiddleware(...middleware)//,
    //    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //    (window.__REDUX_DEVTOOLS_EXTENSION__() )
      
    //    )
    )
}



export default configureStore;