import * as Type from "../../types/membresiasTypes";

const defaultState = {
  membresias: [],
  dataMemberships: [],
};
const concatCustomList = (array1, array2, pagina = 1) => {
  if (array1.length > 0 && pagina > 1) {
    return [...array1, ...array2];
  } else {
    return array2;
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Type.LIST_AVAILABLE_MEMBERSHIPS:
      return {
        ...state,
        membresias: concatCustomList(state.membresias, action.membresias),
      };
    case Type.DATA_MEMBERSHIPS:
      return {
        ...state,
        dataMemberships: action.dataMemberships,
      };
    default: {
      return state;
    }
  }
}
