import { apiComerce } from './eComerceProyect';
import * as Actions from './../redux/actions/message/messageActions';
import * as ActionAuth from './../redux/actions/login/loginAction';
import {
  loadingRequest,
  loadingEndRequest,
} from './../redux/actions/loading/loadingAction';
import {
  activateMaitenance,
  deactivateMaitenance,
} from '../redux/actions/maintenance';
import moment from 'moment';

const _hanldeThen = (res, dispatch, endDispatch = true) => {
  if (endDispatch) {
    dispatch(loadingEndRequest());
  }
  const result = res.data;

  const simpple = localStorage.getItem('redux_localstorage_simple');
  if (result && result.status === 5) {
    if (result.data && result.data.fecha_fin_mantenimiento) {
      dispatch(
        activateMaitenance(
          moment(
            result.data.fecha_fin_mantenimiento,
            'DD/MM/YYYY HH:mm:ss'
          ).format('YYYY-MM-DD HH:mm:ss')
        )
      );
    }
  } else {
    dispatch(deactivateMaitenance());
  }

  if (result && result.status === 0) {
    return Promise.resolve(result);
  } else if (result && result.status === 1 && result.mensaje) {
    if (result.mensaje.toLowerCase().indexOf('token') === -1) {
      dispatch(
        Actions.showMessage({ message: result.mensaje, variant: 'error' })
      );
    }
  } else if (result && result.status === 7) {
    dispatch(ActionAuth.tokenExpire());
  } else {
    return Promise.resolve(result);
  }
};
const _handleThenWithoutDispatch = (res, dispatch) => {
  const result = res.data;
  const simpple = localStorage.getItem('redux_localstorage_simple');

  if (result && result.status === 0) {
    return Promise.resolve(result);
  } else if (result && result.status === 7) {
    dispatch(ActionAuth.tokenExpire());
  } else {
    return Promise.resolve(result);
  }
};

const _hanldeCatch = (error, dispatch) => {
  dispatch(loadingEndRequest());
  if (error.response) {
    const { status, data, config } = error.response;

    if (error.code === 'ECONNABORTED') {
      dispatch(
        Actions.showMessage({ message: 'Tiempo Agotado', variant: 'error' })
      );
    } else if (status === 404) {
      dispatch(
        Actions.showMessage({
          message: 'Error Pagina no encontrada',
          variant: 'error',
        })
      );
    } else if (!error.response) {
      dispatch(
        Actions.showMessage({
          message: 'Verifique su conexion a internet',
          variant: 'error',
        })
      );
    } else {
      dispatch(
        Actions.showMessage({
          message: 'Intente nuevamente por favor',
          variant: 'error',
        })
      );
    }
  } else {
    dispatch(
      Actions.showMessage({
        message: 'Intente nuevamente por favor',
        variant: 'error',
      })
    );
  }
  return Promise.reject(error);
};

export const requestGetLogin = (url, data, dispatch) => {
  dispatch(loadingRequest());
  const headers = { ...data, headers: { ...data } };
  return apiComerce
    .get(url, headers)
    .then((response) => {
      return _hanldeThen(response, dispatch);
    })
    .catch((error) => {
      return _hanldeCatch(error, dispatch);
    });
};

export const requestPostLogin = (url, data, dispatch) => {
  dispatch(loadingRequest());
  return apiComerce
    .post(url, data, null)
    .then((response) => {
      return _hanldeThen(response, dispatch);
    })
    .catch((error) => {
      return _hanldeCatch(error, dispatch);
    });
};

export const requestGet = (url, data, dispatch, endDispatch = true) => {
  dispatch(loadingRequest());
  const token = localStorage.getItem('token');
  const headers = { ...data, headers: { ...data, token: token } };
  return apiComerce
    .get(url, headers)
    .then((response) => {
      return _hanldeThen(response, dispatch, endDispatch);
    })
    .catch((error) => {
      return _hanldeCatch(error, dispatch);
    });
};
export const requestGetWithoutDispatch = (url, data, dispatch) => {
  const token = localStorage.getItem('token');
  const headers = { ...data, headers: { ...data, token: token } };
  return apiComerce
    .get(url, headers)
    .then((response) => {
      return _handleThenWithoutDispatch(response, dispatch);
    })
    .catch((error) => {
      return _hanldeCatch(error, dispatch);
    });
};

export const requestGetWhithHeaders = (url, data, headerParam, dispatch) => {
  dispatch(loadingRequest());
  const token = localStorage.getItem('token');
  const headers = { ...data, headers: { ...headerParam, token: token } };
  return apiComerce
    .get(url, headers)
    .then((response) => {
      return _hanldeThen(response, dispatch);
    })
    .catch((error) => {
      return _hanldeCatch(error, dispatch);
    });
};

export const requestPost = (url, data, dispatch) => {
  dispatch(loadingRequest());
  const token = localStorage.getItem('token');
  const config = {
    headers: { 'Content-Type': 'application/json', token: token },
  };
  return apiComerce
    .post(url, data, config)
    .then((response) => {
      return _hanldeThen(response, dispatch);
    })
    .catch((error) => {
      return _hanldeCatch(error, dispatch);
    });
};
export const requestPostWithoutDispatch = (url, data, dispatch) => {
  dispatch(loadingRequest());
  const token = localStorage.getItem('token');
  const config = {
    headers: { 'Content-Type': 'application/json', token: token },
  };
  return apiComerce
    .post(url, data, config)
    .then((response) => {
      return _handleThenWithoutDispatch(response, dispatch);
    })
    .catch((error) => {
      return _hanldeCatch(error, dispatch);
    });
};

export const requestPostWhithHeaders = (url, data, headerParam, dispatch) => {
  dispatch(loadingRequest());
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      ...headerParam,
      'Content-Type': 'application/json',
      token: token,
    },
  };
  return apiComerce
    .post(url, data, config)
    .then((response) => {
      return _hanldeThen(response, dispatch);
    })
    .catch((error) => {
      return _hanldeCatch(error, dispatch);
    });
};
