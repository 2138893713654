import * as Actions from '../message/messageActions';
import { requestPost, requestGet } from '../../../service/request';

export const LOADING_SALDO_CUENTA = "LOADING_SALDO_CUENTA";

export function cargarSaldoCuenta() {
    return (dispatch) => {
        dispatch({
            type: LOADING_SALDO_CUENTA,
            load: true            
        })
    }
};
