import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import pt from "react-phone-input-2/lang/pt.json";
import "react-phone-input-2/lib/material.css";

import { Assignment } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import useDatosFactura from "../../../../hooks/useDatosFactura";
import { EDITAR_DATOS_FACTURA_AVDEL } from "./../../../../config/config.json";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",
    marginTop: "30px",
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const cedulaIDentidad = 1;

const FormDatosFacturacion = ({
  botonFormDatosFactura = true,
  openFormFactura = false,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const {
    facturaCliente,
    formik,
    tiposDocumento,
    datosFactura,
    openDatosFactura,
    setOpenDatosFactura,
    getDatosFactura,
    registrado,
    setRegistrado,
    setTipoDoc,
  } = useDatosFactura();

  const [openAlert, setOpenAlert] = useState(true);

  useEffect(() => {
    if (
      datosFactura &&
      datosFactura.length > 0 &&
      datosFactura[0].idDatosFactura === 0
    ) {
      setOpenDatosFactura(true);
    } else if (openFormFactura) {
      setOpenDatosFactura(true);
    }
  }, [datosFactura]);

  return facturaCliente ? (
    <>
      {botonFormDatosFactura && (
        <Box
          display="flex"
          justifyContent="center"
          mt={0}
          sx={{ textTransform: "uppercase" }}
        >
          <Alert
            icon={<Assignment fontSize="inherit" />}
            severity="success"
            onClick={() => {
              getDatosFactura();
              setRegistrado(false);
              setOpenDatosFactura(true);
            }}
            style={{
              cursor: "pointer",
              border: "1px solid #4caf50",
              color: "#357a38",
            }}
          >
            {t("datosFactura.textBtnVerDatos")}
          </Alert>
        </Box>
      )}

      {datosFactura && datosFactura.length > 0 && (
        <Dialog
          fullWidth={true}
          maxWidth={"xs"}
          open={openDatosFactura}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            {!registrado ? (
              <div className="pb-3">
                <Typography variant="h5" align="center" component="h2">
                  {t("datosFactura.titulo")}
                </Typography>
                <Box mt={1}>
                  <Collapse in={openAlert}>
                    <Alert
                      severity="warning"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {t("datosFactura.msgInformativo")}
                    </Alert>
                  </Collapse>
                </Box>

                <form onSubmit={formik.handleSubmit} className={classes.form}>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      {t("datosFactura.labelTipoDocumento")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      label={t("datosFactura.labelTipoDocumento")}
                      id="demo-simple-select-outlined"
                      name="tipoDocumento"
                      value={formik.values.tipoDocumento}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setTipoDoc(e.target.value);
                      }}
                      error={Boolean(formik.errors.tipoDocumento)}
                      required
                      disabled={!EDITAR_DATOS_FACTURA_AVDEL}
                    >
                      <MenuItem value="">
                        <em>{t("datosFactura.textItemTipoDoc")}</em>
                      </MenuItem>
                      {tiposDocumento &&
                        tiposDocumento.map((documento) => (
                          <MenuItem
                            key={documento.idTipoDocIdentidad}
                            value={documento.idTipoDocIdentidad}
                          >
                            {documento.descripcion}
                          </MenuItem>
                        ))}
                    </Select>
                    {formik.errors.tipoDocumento && (
                      <FormHelperText error>
                        {formik.errors.tipoDocumento}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Box display="flex" className={classes.formControl}>
                    <TextField
                      id="nroDoc"
                      name="nroDocumento"
                      label={t("datosFactura.labelDocumento")}
                      className=""
                      value={formik.values.nroDocumento}
                      onChange={formik.handleChange}
                      variant="outlined"
                      error={Boolean(formik.errors.nroDocumento)}
                      helperText={
                        formik.errors.nroDocumento && formik.errors.nroDocumento
                      }
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      fullWidth
                      required
                      disabled={!EDITAR_DATOS_FACTURA_AVDEL}
                    />
                    <TextField
                      id="complemento"
                      name="complemento"
                      label={t("datosFactura.labelComplemento")}
                      placeholder="Ej.: 1A"
                      className="ml-2"
                      type="text"
                      InputLabelProps={{
                        shrink:
                          parseInt(formik.values.tipoDocumento) ===
                            cedulaIDentidad && EDITAR_DATOS_FACTURA_AVDEL,
                      }}
                      value={
                        parseInt(formik.values.tipoDocumento) !==
                        cedulaIDentidad
                          ? ""
                          : formik.values.complemento
                      }
                      onChange={formik.handleChange}
                      variant="outlined"
                      error={
                        parseInt(formik.values.tipoDocumento) ===
                          cedulaIDentidad && Boolean(formik.errors.complemento)
                      }
                      helperText={
                        parseInt(formik.values.tipoDocumento) ===
                          cedulaIDentidad &&
                        formik.errors.complemento &&
                        formik.errors.complemento
                      }
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      disabled={
                        !(
                          parseInt(formik.values.tipoDocumento) ===
                            cedulaIDentidad && EDITAR_DATOS_FACTURA_AVDEL
                        )
                      }
                    />
                  </Box>

                  <TextField
                    id="razon-social"
                    name="razonSocial"
                    label={t("datosFactura.labelRazonSocial")}
                    className={classes.formControl}
                    type="text"
                    value={formik.values.razonSocial}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={Boolean(formik.errors.razonSocial)}
                    helperText={
                      formik.errors.razonSocial && formik.errors.razonSocial
                    }
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    fullWidth
                    required
                    disabled={!EDITAR_DATOS_FACTURA_AVDEL}
                  />
                  <TextField
                    id="correo"
                    name="correo"
                    label={t("datosFactura.labelCorreo")}
                    type="email"
                    className={classes.formControl}
                    value={formik.values.correo}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={Boolean(formik.errors.correo)}
                    helperText={formik.errors.correo && formik.errors.correo}
                    fullWidth
                    required
                  />

                  <div className="d-flex align-items-top justify-content-between">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      style={{
                        minWidth: "125px",
                        width: "125px",
                        paddingRight: "5px",
                      }}
                    >
                      <PhoneInput
                        inputStyle={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#FFFFFF",
                        }}
                        inputProps={{
                          readOnly: true,
                          label: t("updateDataUser.labelCodigoPais"),
                          name: "prefijo",
                          id: "prefijo",
                        }}
                        country={"bo"}
                        name="prefijo"
                        specialLabel={t("updateDataUser.labelCodigoPais")}
                        value={formik.values.prefijo}
                        onChange={(prefijo) =>
                          formik.setValues({
                            ...formik.values,
                            prefijo: `${prefijo}`,
                          })
                        }
                        enableSearch={false}
                        countryCodeEditable={false}
                        localization={
                          t("updateDataUser.idioma") === "es"
                            ? es
                            : t("updateDataUser.idioma") === "pt" && pt
                        }
                      />
                    </FormControl>

                    <TextField
                      id="telefono"
                      name="telefono"
                      label={t("datosFactura.labelTelefono")}
                      className={classes.formControl}
                      type="tel"
                      value={formik.values.telefono}
                      onChange={formik.handleChange}
                      variant="outlined"
                      error={Boolean(formik.errors.telefono)}
                      helperText={
                        formik.errors.telefono && formik.errors.telefono
                      }
                      fullWidth
                    />
                  </div>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ marginTop: "25px" }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      disabled={
                        Object.keys(formik.errors).length === 0
                          ? false
                          : parseInt(formik.values.tipoDocumento) !==
                              cedulaIDentidad && formik.errors.complemento
                          ? false
                          : true
                      }
                    >
                      {t("datosFactura.btnTextEnviar")}
                    </Button>
                  </FormControl>
                  {datosFactura[0].idDatosFactura !== 0 && (
                    <Button
                      variant="contained"
                      className="mt-3"
                      onClick={() => setOpenDatosFactura(false)}
                    >
                      {t("datosFactura.textBtnCancelar")}
                    </Button>
                  )}
                </form>
              </div>
            ) : (
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CheckCircleOutlineIcon
                  style={{ fontSize: "70px", color: "#2DA559" }}
                />
                <p style={{ color: "#A0A0A0", marginTop: "5px" }}>
                  {t("datosFactura.textDatosRegistrados")}
                </p>

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginTop: "10px", marginBottom: "15px" }}
                >
                  <Button
                    onClick={() => {
                      setOpenDatosFactura(false);
                    }}
                    type="button"
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    {t("datosFactura.btnAceptar")}
                  </Button>
                </FormControl>
              </div>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  ) : null;
};

export default FormDatosFacturacion;
