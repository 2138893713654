import { useState } from "react";
import { useDispatch } from "react-redux";
import * as ActionMenu from "../redux/actions/home/menuActions";
import { showMessage } from "../redux/actions/message/messageActions";
import { requestGet, requestPost } from "../service/request";

const useCalcularRecuperacion = (history) => {
  const dispatch = useDispatch();

  const [puedeCalcular, setPuedeCalcular] = useState(false);
  const [porcentajeMax, setPorcentajeMax] = useState(0);
  const [clienteData, setClienteData] = useState(null);
  const [reversionesData, setReversionesData] = useState(null);
  const [proyectosData, setProyectosData] = useState(null);
  const [productosData, setProductosData] = useState(null);

  const getVerificarUsuario = async () => {
    try {
      const response = await requestGet("/calculadora/recuperacion/verificar", {}, dispatch);
      if (response && response.status === 0) {
        if (response.data.verificado) {
          dispatch(ActionMenu.addMenuCalcular());
          setPuedeCalcular(response.data.verificado);
          setPorcentajeMax(response.data.porcentajeMaximoMontoReconocido);
        } else {
          history && history.replace("/");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReversiones = async (docId) => {
    if (docId.length > 3) {
      try {
        const response = await requestPost(
          "/calculadora/recuperacion/obtener/reversiones",
          { docId },
          dispatch
        );

        if (response && response.status === 0) {
          setReversionesData(response.data.reversiones);
          setClienteData(response.data.datosCliente);
        } else {
          setReversionesData(null);
          setClienteData(null);
          dispatch(
            showMessage({
              message: response.error,
              variant: "info",
            })
          );
        }
      } catch (error) {}
    }
  };

  const getProyectos = async (reversion) => {
    try {
      const response = await requestPost(
        "/calculadora/recuperacion/obtener/proyectos",
        { idEmpresa: reversion.idEmpresa, idVentaRevertida: reversion.idVenta },
        dispatch
      );

      if (response && response.status === 0) {
        setProyectosData(response.data);
        setProductosData(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProductos = async (data) => {
    console.log(data);
    try {
      const response = await requestPost(
        "/calculadora/recuperacion/obtener/productos",
        data,
        dispatch
      );

      if (response && response.status === 0) {
        setProductosData(response.data);
      }
    } catch (error) {}
  };

  return {
    getVerificarUsuario,
    puedeCalcular,
    getReversiones,
    clienteData,
    reversionesData,
    getProyectos,
    proyectosData,
    getProductos,
    productosData,
    setProductosData,
    porcentajeMax,
  };
};

export default useCalcularRecuperacion;
