import { apiComerce } from "../../service/eComerceProyect";
import { requestGet } from "../../service/request";
import moment from "moment";

export const ACTIVATE_MAINTENANCE = "ACTIVATE_MAINTENANCE";
export const DEACTIVATE_MAINTENANCE = "DEACTIVATE_MAINTENANCE";

export const activateMaitenance = (endDate) => ({
  type: ACTIVATE_MAINTENANCE,
  endDate,
});

export const deactivateMaitenance = () => ({
  type: DEACTIVATE_MAINTENANCE,
});

export const checkMaitenance = () => {
  return async (dispatch, getState) => {
    try {
      let response = await apiComerce.get("/user/verificar/mantenimiento");
      const result = response.data;
      if (result.status === 1) {
        dispatch(
          activateMaitenance(
            moment(
              result.data.fecha_fin_mantenimiento,
              "DD/MM/YYYY HH:mm:ss"
            ).format("YYYY-MM-DD HH:mm:ss")
          )
        );
      } else {
        dispatch(deactivateMaitenance());
      }
    } catch (error) {
      dispatch(deactivateMaitenance());
      console.log(error);
    }
  };
};
