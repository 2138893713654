
  import * as Types from './../../types/reservaType';
  const defaultState = {      
      modalReserva:false,
      mensajeReserva:'',      
      tituloModalReserva:'',
      tipoModalReserva:''
  };
  
  export default function reducer(initialState = defaultState, action) {
      let state = {...defaultState, ...initialState}
      switch (action.type) {
          case Types.RESERVA_REGISTRO_RECONFIGURACION_PLAN_PAGO:
              return {
                  ...state,                  
                  modalReserva: true,
                  mensajeReserva: action.mensajeReserva,      
                  tituloModalReserva: action.tituloModalReserva,
                  tipoModalReserva:'success'                             
              };                 
          case Types.RESERVA_REGISTRO_RECONFIGURACION_PLAN_PAGO_CERRAR_MODAL:
              return {
                  ...state,                  
                  modalReserva: false,
                  mensajeReserva: "",      
                  tituloModalReserva: "",
                  tipoModalReserva:'success'                             
              };                 
          default: {
              return state;
          }
      }
  }