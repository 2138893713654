import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTimer } from "react-timer-hook";
import moment from "moment";
import config from "../config/config.json";
import {
  cambiarExpandirReloj,
  closeSesionPusher,
  closeSesionTimeout,
  unlogin,
} from "../redux/actions/login/loginAction";
import { useTranslation } from "react-i18next";

const useSessionTimer = ({ history }) => {
  const { t } = useTranslation();
  const userRef = useRef(null);
  const { userToken, expandirReloj } = useSelector((state) => state.load);

  const { seconds, minutes, isRunning, pause, restart } = useTimer({
    expiryTimestamp: moment()
      .add(config.TIEMPO_FINALIZACION_SESION, "seconds")
      .toDate(),
    onExpire: () => {
      if (window.ReactNativeWebView) {
        dispatch(
          closeSesionTimeout(
            {
              title: t("homePage.sesionInactividadTitulo"),
              message: t("homePage.sesionInactividadMensaje"),
              button: t("homePage.sessionInactividadBoton"),
            },
            history
          )
        );
      } else {
        dispatch(unlogin(true, history));
      }
    },
  });

  const toggleWatch = () => dispatch(cambiarExpandirReloj());

  const dispatch = useDispatch();

  useEffect(() => {
    // actualizarTiempoSessionRedux();
  }, [seconds]);

  useEffect(() => {
    userRef.current = userToken;
    if (userToken) {
      restart(moment().add(config.TIEMPO_FINALIZACION_SESION, "seconds"), true);
    } else {
      pause();
    }
  }, [userToken]);

  const onClickBody = () => {
    if (userRef.current) {
      restart(moment().add(config.TIEMPO_FINALIZACION_SESION, "seconds"), true);
    }
  };

  useEffect(() => {
    let body = document.body;
    body.addEventListener("click", onClickBody, true);
    return () => {
      body.removeEventListener("click", onClickBody, true);
    };
  }, []);
  return {
    seconds,
    minutes,
    expandirReloj,
    toggleWatch,
  };
};

export default useSessionTimer;
