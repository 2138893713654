import currencyReducer from "./currencyReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import menuReducer from "./home/menuReducer";
import loginReducer from "./login/loginReducer";
import messageReducer from "./message/messageReducers";
import saldoCuentaReducer from "./saldoCuenta/saldoCuentaReducer";
import loadingReducer from "./loading/loadingReducer";
import notificacionReducer from "./notificacion/notificacionReducer";
import homeReducer from "./home/homeReducer";
import membershipsReducer from "./membresias/membresiasReducer";
import projectsReducer from "./proyectos/proyectosReducer";
import prereservaReducer from "./prereserva/prereservaReducer";
import reservaReducer from "./reserva/reservaReducer";
import maintenance from "./maintenance";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  home: homeReducer,
  memberships: membershipsReducer,
  projects: projectsReducer,
  message: messageReducer,
  menu: menuReducer,
  load: loginReducer,
  saldoCuenta: saldoCuentaReducer,
  requestloading: loadingReducer,
  notificaciones: notificacionReducer,
  prereserva: prereservaReducer,
  reserva: reservaReducer,
  maintenance,
});

export default rootReducer;
