import axios from "axios";
import config from "../config/config.json";

const apiUrl = () => {
  const prefix = config.USAR_HTTPS ? "https" : "http";
  if (config.USAR_PUERTO) {
    return `${prefix}://${config.SERVIDOR}:${config.PUERTO}/sion/api/`;
  } else {
    return `${prefix}://${config.SERVIDOR}/sion/api/`;
  }
};

export const apiComerce = axios.create({
  baseURL: apiUrl(),
  timeout: 60000,
  headers: {
    agente: 109, // AGENTE PETICION SION_OFFICE
    token: "",
  },
});

apiComerce.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const savedLanguage = localStorage.getItem("currentLanguage");
    config.headers["Accept-Language"] = savedLanguage ? savedLanguage : "en";
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

apiComerce.interceptors.response.use(
  function (res) {
    return res;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
