
import {
  LOADING_MENU,LOADING_MENU_FLOTANTE
} from "../../actions/home/menuActions";

const defaultState = {
    load:false,
    menu:[],
    subMenu:[],
    menuFlotante:[]
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case LOADING_MENU:
            return {
                ...state,
                load: action.load,
                menu: action.listMenu,                           
            }; 
        case LOADING_MENU_FLOTANTE:
            return {
                ...state,
                subMenu: action.listSubMenuFlotante                              
            };     
        default: {
            return state;
        }
    }
}