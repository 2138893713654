import {
  requestPost,
  requestGet,
  requestGetLogin,
  requestPostWhithHeaders,
  requestGetWhithHeaders,
  requestPostLogin,
} from "../../../service/request";
import moment from "moment";
import * as Actions from "../message/messageActions";
import { sesionFallidaMovil } from "../../../lib/FunsionesGenerales";
import { clear } from "redux-localstorage-simple";
import { encrypt } from "../../../lib/Encript";
import i18next from "i18next";
import { apiComerce } from "../../../service/eComerceProyect";

export const LOADING_LOGIN = "LOADING_LOGIN";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_FINISH = "SIGN_FINISH";
export const UPDATE_AUTH_TYPE = "UPDATE_AUTH_TYPE";
export const UPDATE_OPEN_DIALOG = "UPDATE_OPEN_DIALOG";
export const HIDE_CI_MODAL = "HIDE_CI_MODAL";
export const CAMBIAR_EXPANDIR_RELOJ = "CAMBIAR_EXPANDIR_RELOJ";


export function iniciarSesion(carnet, password, history = null, callbackOnFail = () => null) {
  return async (dispatch, getState) => {
    const data = {
      user: carnet,
      password: password,
      dispositivo: "login.idDispositivo44",
      tokenFCM: getState().load.tokenFCM,
    };
    let responseLogin = await requestPostLogin("user/login/nuevo", data, dispatch);
    if (responseLogin) {
      if (responseLogin.status === 0) {
        localStorage.setItem("token", responseLogin.data.token);
        localStorage.setItem("carnetUser", responseLogin.data.ci);
        const dataRegisterTokenFCM = {
          ciUser: carnet,
          tokenDispositivo: "",
          imei: "",
        };
        const header = {
          token: responseLogin.data.token,
        };

        let responseLoginRegistro = await requestPostWhithHeaders(
          "token/user/register",
          dataRegisterTokenFCM,
          header,
          dispatch
        );

        let responseLanguage = await apiComerce.post(
          "user/preferencia/obtener/idioma",
          {},
          {
            headers: {
              ...header,
              "Content-Type": "application/json",
            },
          }
        );
        responseLanguage = responseLanguage.data;
        if (responseLanguage && responseLanguage.status === 0) {
          if (responseLanguage.data) {
            i18next.changeLanguage(responseLanguage.data);
          }
        } else {
          const searchParams = new URLSearchParams(window.location.search);
          const searchLanguage = searchParams.get("lang");
          const currentLanguage = localStorage.getItem("currentLanguage");
          const lenguaje = searchLanguage
            ? searchLanguage
            : currentLanguage
            ? currentLanguage
            : "es";
          let body = { lenguaje };
          let url = "/user/preferencia/actualizar/lenguaje";
          let responseSyncLanguage = await requestPostWhithHeaders(
            url,
            body,
            header,
            dispatch
          );
          moment.locale(lenguaje);
        }

        dispatch({
          type: LOADING_LOGIN,
          token: responseLogin.data.token,
          tipo: "web",
          idDispositivo: "",
          puedeIngresar: responseLogin.data.puedeIngresar,
          TipoUsuario: responseLogin.data.TipoUsuario,
          nombreUsuario: responseLogin.data.nombreUsuario,
          esVendedor: responseLogin.data.esVendedor,
          mensajeVendedor:
            responseLogin.data.mensajeVendedor === null
              ? " "
              : responseLogin.data.mensajeVendedor,
          ci: responseLogin.data.ci,
          iat: responseLogin.data.iat,
          exp: responseLogin.data.exp,
          cantidadDiasLoginCarnet: responseLogin.data.cantidadDiasLoginCarnet,
          esCajero: responseLogin.data.esCajero
        });
      } else {
        if (responseLogin.status === 2) {
          if (history) {
            history.push(
              "/cambiar-pass/" + encodeURIComponent(encrypt(carnet))
            );
          }
        } else {
          callbackOnFail();
          dispatch(
            Actions.showMessage({
              message: responseLogin.data.mensaje,
              variant: "error",
            })
          );
        }
      }
    }else{
      callbackOnFail();
    }
  };
}

const inicializarToken = async({dispatch})=>{
  localStorage.removeItem("token");
} 
export function iniciarSesionPath(tipoDispositivo, mac, token, history) {

  return async (dispatch) => {

    await inicializarToken({dispatch})
    localStorage.setItem("token", token);
    const data = {};
    requestGet("user/sesion/movil", data, dispatch).then(async (response) => {
      if (response != undefined && response.status == 0) {
        let responseLanguage = await apiComerce.post(
          "user/preferencia/obtener/idioma",
          {},
          {
            headers: {
              token,
              "Content-Type": "application/json",
            },
          }
        );
        responseLanguage = responseLanguage.data;
        if (responseLanguage && responseLanguage.status === 0) {
          if (responseLanguage.data) {
            i18next.changeLanguage(responseLanguage.data);
          }
        } else {
          const searchParams = new URLSearchParams(window.location.search);
          const searchLanguage = searchParams.get("lang");
          const currentLanguage = localStorage.getItem("currentLanguage");
          const lenguaje = searchLanguage
            ? searchLanguage
            : currentLanguage
            ? currentLanguage
            : "es";
          let body = { lenguaje };
          let url = "/user/preferencia/actualizar/lenguaje";
          let responseSyncLanguage = await requestPostWhithHeaders(
            url,
            body,
            { token },
            dispatch
          );
          moment.locale(lenguaje);
        }

        dispatch({
          type: LOADING_LOGIN,
          token: token,
          tipo: tipoDispositivo,
          idDispositivo: mac,
          puedeIngresar: response.data.puedeIngresar,
          TipoUsuario: response.data.TipoUsuario,
          nombreUsuario: response.data.nombreUsuario,
          esVendedor: response.data.esVendedor,
          mensajeVendedor: response.data.mensajeVendedor,
          cantidadDiasLoginCarnet: response.data.cantidadDiasLoginCarnet,
          ci: response.data.ci,
          iat: response.data.iat,
          exp: response.data.exp,
          esCajero: response.data.esCajero
        });
        history.push("/");
      } else {
        sesionFallidaMovil();
      }
    });
  };
}
export function recargarSesion(token) {
  return (dispatch) => {
    localStorage.setItem("token", token);
    const data = {};
    requestGet("user/sesion/movil/datos", data, dispatch)
      .then((response) => {
        if (response != undefined && response.status == 0) {
          dispatch({
            type: LOADING_LOGIN,
            token: token,
            tipo: response.data.tipoDispositivo,
            idDispositivo: response.data.macFirebase,
            puedeIngresar: response.data.puedeIngresar,
            TipoUsuario: response.data.TipoUsuario,
            nombreUsuario: response.data.nombreUsuario,
            esVendedor: response.data.esVendedor,
            mensajeVendedor: response.data.mensajeVendedor,
            ci: response.data.ci,
            iat: response.data.iat,
            exp: response.data.exp,
            esCajero: response.data.esCajero
          });
        } else {
          dispatch(loadFinish());
          localStorage.removeItem("token");
        }
      })
      .catch((e) => {
        console.log("Error Loading: ", e);
        dispatch(loadFinish());
      });
  };
}
export function closeSesion(history = null) {
  return (dispatch) => {
    dispatch({
      type: SIGN_OUT,
    });
    localStorage.removeItem("token");
    if (history) {
      history.push("/");
    }
  };
}
export function closeSesionPusher(history) {
  return (dispatch, getState) => {
    const tipo = getState().load.tipo;
    if (!window.ReactNativeWebView) {
      localStorage.removeItem("token");
      dispatch({
        type: SIGN_OUT,
      });
      dispatch(
        Actions.showMessage({
          message: i18next.t("reducer.sesionExpiro"),
          variant: "error",
        })
      );
      if (history) {
        history.push("/");
      }
    } else {
      window.localStorage.removeItem("token");
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            code: "SESSION_EXPIRED",
            message: i18next.t("reducer.sesionExpiro"),
          })
        );
        localStorage.removeItem("token");
        return;
      } else {
        localStorage.removeItem("token");
        dispatch(
          Actions.showMessage({
            message: i18next.t("reducer.expiroMovil"),
            variant: "error",
          })
        );
      }
    }
  };
}

export function tokenExpire() {
  return (dispatch, getState) => {
    const tipo = getState().load.tipo;
    dispatch({
      type: SIGN_FINISH,
    });
    if ((!window.ReactNativeWebView && tipo == "web") || tipo == "") {
      window.localStorage.removeItem("token");
      dispatch({
        type: SIGN_OUT,
      });
      if (tipo.length > 0) {
        dispatch(
          Actions.showMessage({
            message: i18next.t("reducer.sesionExpiro"),
            variant: "error",
          })
        );
      }
    } else {
      window.localStorage.removeItem("token");
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            code: "SESSION_EXPIRED",
            message: i18next.t("reducer.expiroApi"),
          })
        );
        return;
      } else {
        dispatch({
          type: SIGN_FINISH,
        });
        if (tipo.length > 0) {
          dispatch(
            Actions.showMessage({
              message: i18next.t("reducer.expiroMovil"),
              variant: "error",
            })
          );
        }
      }
    }
  };
}
export function unlogin(timeoutSesion = false, history = null) {
  return (dispatch) => {
    const data = {};
    requestPost("user/unlogin", data, dispatch).then((response) => {
      if (response != undefined && response.status == 0) {
        if(timeoutSesion){
          dispatch(closeSesionPusher(history));
        }
      } else {
        sesionFallidaMovil();
      }
    });
  };
}
export function servicePrueba() {
  return (dispatch) => {
    //---------------------------------------------------
    localStorage.setItem("token", "12345679");

    const body = {
      docId: "1356",
      producto: "SDSDSDccc",
    };
    requestGet("/generarpin", body, dispatch).then((response) => {
      if (response) {
        dispatch({
          type: LOADING_LOGIN,
          token: "JMDFs123467897AAA",
          tipo: "web",
          idDispositivo: "",
        });
      }
    });

    //---------------------------------------------------
  };
}

export function closeSesionTimeout(messages, history) {
  return (dispatch, getState) => {
    const tipo = getState().load.tipo;
    if (!window.ReactNativeWebView) {
      localStorage.removeItem("token");
      dispatch({
        type: SIGN_OUT,
      });
      dispatch(
        Actions.showMessage({
          message: i18next.t("reducer.sesionExpiro"),
          variant: "error",
        })
      );
      if (history) {
        history.push("/");
      }
    } else {
      window.localStorage.removeItem("token");
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            code: "LOGOUT_MESSAGE",
            data: messages,
          })
        );
        localStorage.removeItem("token");
        return;
      } else {
        localStorage.removeItem("token");
        dispatch(
          Actions.showMessage({
            message: i18next.t("reducer.expiroMovil"),
            variant: "error",
          })
        );
      }
    }
  };
}

export function loadFinish() {
  return (dispatch) => {
    dispatch({
      type: SIGN_FINISH,
    });
  };
}

export const syncFingerprintPrefAction = (payload) => ({
  type: UPDATE_AUTH_TYPE,
  ...payload
})

export const updateOpenFingerprintDialog = (payload) => ({
  type: UPDATE_OPEN_DIALOG,
  open: payload.open
})

export const cambiarExpandirReloj = (payload) => ({
  type: CAMBIAR_EXPANDIR_RELOJ,
})