import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { requestGet, requestPost } from "../service/request";

import { useTranslation } from "react-i18next";

const useUpdateUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [userUpdate, setUserUpdate] = useState(null);

  const [paisesData, setPaisesData] = useState(null);
  const [ciudadesData, setCiudadesData] = useState(null);
  const [registrado, setRegistrado] = useState(false);

  const verifyUserUpdate = async () => {
    const url = "/datos/cliente/verificar/actualizar";

    const response = await requestGet(url, {}, dispatch);

    if (response && response.data) {
      setUserUpdate(response.data);
    }
  };

  const getPaisesData = async () => {
    const url = "/paises/lista";

    const response = await requestGet(url, {}, dispatch);
    if (response && response.data) {
      setPaisesData(response.data);
    }
  };

  const getCiudadesData = async (idPais) => {
    const url = "/ciudades/lista";

    const response = await requestGet(url, { pais: idPais }, dispatch);
    if (response && response.data) {
      setCiudadesData(response.data);
    }
  };

  const setUpdateUserData = async (data) => {
    setLoading(true);
    if (!data.prefijo.includes("+")) {
      data.prefijo = "+" + data.prefijo;
    }
    const newData = { ...data, telefono: `${data.prefijo}-${data.telefono}` };
    delete newData.prefijo;
    delete newData.actualizacionRealizada;
    const url = "datos/cliente/actualizar";

    const response = await requestPost(url, newData, dispatch);

    if (response && response.data) {
      verifyUserUpdate();
      setRegistrado(true);
      setLoading(false);
    }
    setLoading(false);
  };

  const validationSchema = yup.object({
    correo: yup
      .string()
      .email(t("updateDataUser.errorCorreo"))
      .required(t("updateDataUser.correoRequerido")),
    paisId: yup.number().min(1).required(t("updateDataUser.seleccionarPais")),
    ciudadId: yup
      .number()
      .typeError(t("updateDataUser.seleccionarCiudad"))
      .min(-1, t("updateDataUser.seleccionarCiudad"))
      .required(t("updateDataUser.seleccionarCiudad")),
    telefono: yup
      .number()
      .typeError(t("updateDataUser.telefonoNoNumero"))
      .required(t("updateDataUser.telefonoRequerido"))
      .test(
        "len",
        t("updateDataUser.telefonoMinDigitos", { minDigitos: 5 }),
        (val) => val && val.toString().length >= 5
      )
      .test(
        "len",
        t("updateDataUser.telefonoMaxDigitos", { maxDigitos: 10 }),
        (val) => val && val.toString().length <= 10
      ),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      correo: "",
      paisId: null,
      ciudadId: null,
      prefijo: "",
      telefono: "",
    },
    onSubmit: async (data) => {
      await setUpdateUserData(data);
    },
  });

  useEffect(() => {
    verifyUserUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userUpdate && !userUpdate.actualizacionRealizada) {
      getPaisesData();
      formik.setValues(userUpdate);
      getCiudadesData(userUpdate.paisId ? userUpdate.paisId : 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdate]);

  return {
    loading,
    userUpdate,
    verifyUserUpdate,
    paisesData,
    getCiudadesData,
    ciudadesData,
    formik,
    registrado,
  };
};

export default useUpdateUser;
