import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import useUpdateUser from "../../../hooks/useUpdateUser";

import imgAlert from "../../../assets/images/megaphoneicon.png";

import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import pt from "react-phone-input-2/lang/pt.json";
import "react-phone-input-2/lib/material.css";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",
    marginTop: "30px",
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  imgContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#C8E2F7",
    width: "130px",
    height: "130px",
    padding: "5px",
    borderRadius: "50%",
  },
  imgAlert: {
    width: "100%",
    height: "100%",
  },
}));

const UpdateDataUser = ({ setDatosActualizados }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const {
    userUpdate,
    paisesData,
    getCiudadesData,
    ciudadesData,
    formik,
    registrado,
  } = useUpdateUser();

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (userUpdate && !userUpdate.actualizacionRealizada) {
      setDatosActualizados(false);
      setOpenDialog(true);
    }

    if (userUpdate && userUpdate.actualizacionRealizada && !openDialog) {
      setDatosActualizados(true);
    }

  }, [userUpdate]);

  function obtenerPrefijoPorId(idpais) {
    const idpaisNumero = parseInt(idpais, 10); // Convierte idpais a número
    const paisEncontrado = paisesData.find((pais) => pais.idpais === idpaisNumero);

    if (paisEncontrado) {
      return paisEncontrado.PrefijoTelefono;
    }
    return null; // No se encontró el país con ese idpais
  }

  const handleChangePais = (event) => {
    const prefijoCelular = obtenerPrefijoPorId(event.target.value);
    console.log(prefijoCelular);

    formik.setValues({
      ...formik.values,
      prefijo: prefijoCelular,
    })

    formik.setFieldValue("ciudadId", null);
    formik.handleChange(event);
    getCiudadesData(event.target.value);
  };

  const [keyPress, setKeyPress] = useState("");

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={openDialog}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogContent>
        {!registrado ? (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className={classes.imgContent} style={{}}>
                <img src={imgAlert} alt="" className={classes.imgAlert} />
              </div>
            </div>
            <DialogTitle
              id="max-width-dialog-title"
              style={{
                textAlign: "center",
                paddingBottom: "0px",
              }}
            >
              {t("updateDataUser.titulo")}
            </DialogTitle>
            <DialogContentText
              style={{ textAlign: "center", color: "#A0A0A0" }}
            >
              {t("updateDataUser.subTitulo")}
            </DialogContentText>

            <form
              onSubmit={formik.handleSubmit}
              className={classes.form}
              noValidate
            >


              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("updateDataUser.labelPais")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="paisId"
                  value={
                    paisesData && formik.values.paisId
                      ? formik.values.paisId
                      : ""
                  }
                  onChange={(e) => handleChangePais(e)}
                  label={t("updateDataUser.labelPais")}
                  error={Boolean(formik.errors.paisId)}
                  required
                >
                  <MenuItem value="">
                    <em>{t("updateDataUser.textItemPais")}</em>
                  </MenuItem>
                  {paisesData &&
                    paisesData.length > 0 &&
                    paisesData.map((paisData) => (
                      <MenuItem key={paisData.idpais} value={paisData.idpais}>
                        {paisData.nombre}
                      </MenuItem>
                    ))}
                </Select>
                {formik.errors.paisId && (
                  <FormHelperText error>{formik.errors.paisId}</FormHelperText>
                )}
              </FormControl>

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined1-label">
                  {t("updateDataUser.labelCiudad")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined1"
                  name="ciudadId"
                  value={
                    ciudadesData && formik.values.ciudadId
                      ? formik.values.ciudadId
                      : ""
                  }
                  onChange={formik.handleChange}
                  label={t("updateDataUser.labelCiudad")}
                  error={Boolean(formik.errors.ciudadId)}
                >
                  <MenuItem value="">
                    <em>{t("updateDataUser.textItemCiudad")}</em>
                  </MenuItem>
                  {ciudadesData &&
                    ciudadesData.length > 0 &&
                    ciudadesData.map((ciudadData) => (
                      <MenuItem
                        key={ciudadData.idciudad}
                        value={
                          ciudadData.idciudad === 0 ? -1 : ciudadData.idciudad
                        }
                      >
                        {ciudadData.nombre}
                      </MenuItem>
                    ))}
                </Select>
                {formik.errors.ciudadId && (
                  <FormHelperText error>
                    {formik.errors.ciudadId}
                  </FormHelperText>
                )}
              </FormControl>

              <div className="d-flex align-items-top justify-content-between">
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{
                    minWidth: "125px",
                    width: "125px",
                    paddingRight: "5px",
                  }}
                >
                  <PhoneInput
                    containerStyle={{
                      pointerEvents: "none", // Evita eventos de puntero en el contenedor
                    }}
                    inputStyle={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#FFFFFF",
                    }}
                    inputProps={{
                      label: t("updateDataUser.labelCodigoPais"),
                      name: "prefijo",
                      id: "prefijo",
                      readOnly: true, // Agregar la propiedad readOnly
                    }}
                    country={"bo"}
                    name="prefijo"
                    specialLabel={t("updateDataUser.labelCodigoPais")}
                    value={formik.values.prefijo}
                    onChange={() => { }} // Mantén la función onChange vacía
                    enableSearch={false}
                    countryCodeEditable={false}
                    localization={
                      t("updateDataUser.idioma") === "es"
                        ? es
                        : t("updateDataUser.idioma") === "pt" && pt
                    }
                  />

                </FormControl>

                <TextField
                  id="standard-number"
                  className={classes.formControl}
                  name="telefono"
                  label={t("updateDataUser.labelTelefono")}
                  value={formik.values.telefono}
                  onKeyDownCapture={(e) => setKeyPress(e.key)}
                  tabIndex="-1"
                  onChange={(e) => {
                    keyPress !== "." &&
                      keyPress !== "e" &&
                      formik.handleChange(e);
                  }}
                  variant="outlined"
                  inputlabelprops={{ shrink: true }}
                  error={Boolean(
                    formik.values.telefono && Boolean(formik.errors.telefono)
                  )}
                  helperText={formik.errors.telefono && formik.errors.telefono}
                  required
                  style={{ width: "100%" }}
                />
              </div>

              <TextField
                id="outlined-error-helper-text"
                name="correo"
                label={t("updateDataUser.labelCorreo")}
                value={formik.values.correo}
                onChange={formik.handleChange}
                variant="outlined"
                inputlabelprops={{ shrink: true }}
                error={Boolean(formik.errors.correo)}
                helperText={formik.errors.correo && formik.errors.correo}
                required
                className={classes.formControl}
              />

              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ marginTop: "25px", marginBottom: "15px" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={
                    Object.keys(formik.errors).length === 0 ? false : true
                  }
                >
                  {t("updateDataUser.btnActualizar")}
                </Button>
              </FormControl>
            </form>
          </>
        ) : (
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CheckCircleOutlineIcon
              style={{ fontSize: "90px", color: "#2DA559" }}
            />
            <p style={{ color: "#A0A0A0", marginTop: "5px" }}>
              {t("updateDataUser.textActualizacionCorrecta")}
            </p>

            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ marginTop: "10px", marginBottom: "15px" }}
            >
              <Button
                onClick={() => {
                  setOpenDialog(false);
                  setDatosActualizados(true);;
                }}
                type="button"
                variant="contained"
                size="large"
                color="primary"
              >
                {t("updateDataUser.btnAceptar")}
              </Button>
            </FormControl>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateDataUser;
