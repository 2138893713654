import React from "react";
import useNotificacionesPendientes from "../../hooks/useNotificacionesPendientes";
import DialogNotificacionMultiple from "./DialogNotificacionMultiple";

const NotificacionesPendientes = () => {
  const { notificaciones, modalVisible, cerrarModal } =
    useNotificacionesPendientes();
  return (
    notificaciones.length > 0 && (
      <DialogNotificacionMultiple
        notificaciones={notificaciones}
        modalVisible={modalVisible}
        onClose={cerrarModal}
      />
    )
  );
};

export default NotificacionesPendientes;
